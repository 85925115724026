export default {
  //登录
  login: '/admin/login',


  //查询异常支付
  checkBalanceRecord: '/admin/checkBalanceRecord',
  //重试异常支付
  retryBalanceRecord: '/admin/retryBalanceRecord',

  //获取七牛云
  getUploadToken: '/admin/qiniu/getUploadToken',
  //获取banner
  getBannerList: '/admin/getBannerList',

  //添加banner
  addBanner: '/admin/saveBanner',
  //编辑banner
  updateBanner: '/admin/updateBannerById',
  //删除banner
  deleBanner: '/admin/deleteBannerById',


  /**
   * 版本操作相关
   */
  //获取banner
  getVersionList: '/admin/getVersionList',
  //添加版本
  //添加版本
  addVersion: '/admin/saveVersion',
  //编辑版本
  updateVersion: '/admin/updateVersionById',
  //删除版本
  deleVersion: '/admin/deleteVersionById',
  //获取财务列表
  getFinanceList: '/admin/getFinanceListByTime',

  //财务生成Excel
  getFinanceExcel: '/admin/getFinanceListExcelByTime',

  //下载财务excel
  downloadFinanceExcel: '/admin/download',

  /**
   * 推广员审核模块
   */

  //查询所有的推广大使申请
  getAmbassadorApplyPage: '/admin/getAmbassadorApplyPage',

  //同意，通过申请
  passAmbassadorById: '/admin/passAmbassadorById',

  //下载推广大使的凭证
  downloadAmbassadorMaterial: '/admin/downloadAmbassadorMaterial',

  //活动三接口
  //精选
  getNiceAction: '/banzhu/getRecommendActionPage',

  //最新
  getNewAction: '/banzhu/getLatstActionPage',

  getUserReportList: '/admin/getUserReportList',
  getUserReportPage: '/admin/getUserReportPage',

  checkUserReport: '/admin/checkUserReport',

  //获取橱窗申请列表
  getMerchantApplyList: "/admin/getMerchantApplyList",
  //审核橱窗
  auditMerchantApply: "/admin/auditMerchantApply",

  getApplyPlaceByPage: "/admin/getApplyPlaceByPage",
  judgeApplyPlace: "/admin/judgeApplyPlace",

  getUserPage: "/admin/getUserPage",
  updateUserBlacklist: "/admin/updateUserBlacklist",
  saveTagBanner: "/admin/saveTagBanner",
  getTagBannerPage: "/admin/getTagBannerPage",
  deleteTagBannerById: "/admin/deleteTagBannerById",
  updateTagBannerById: "/admin/updateTagBannerById",
  getTagList: "/admin/getTagList",
  getTagPage: "/admin/getTagPage",
  updateTagById: "/admin/updateTagById",
  saveTag: "/admin/saveTag",
  saveMerch: "/admin/saveMerch",
  getMerchPage: "/admin/getMerchPage",
  getMerchById: "/admin/getMerchById",
  updateMerchById: "/admin/updateMerchById",
  deleteMerchById: "/admin/deleteMerchById",
  getMerchPropsTemplateList: "/admin/getMerchPropsTemplateList",
  getMerchDispatchTemplateList: "/admin/getMerchDispatchTemplateList",
  getMerchBillPage: "/admin/getMerchBillPage",
  downloadMerchBill: "/admin/downloadMerchBill",
  batchDispatchMerch: "/admin/batchDispatchMerch",
  getAllProvince: "/admin/area/getAllProvince",
  saveHotMerch: "/admin/saveHotMerch",
  getHotMerchList: "/admin/getHotMerchList",
  updateHotMerchById: "/admin/updateHotMerchById",
  getMerchWantList: "/admin/getMerchWantList",
  updateMerchWantById: "/admin/updateMerchWantById",
  saveMerchant: "/admin/saveMerchant",
  updateMerchantById: "/admin/updateMerchantById",
  getMerchantPage: "/admin/getMerchantPage",
  deleteMerchantById: "/admin/deleteMerchantById",
  getVipRightsList: "/admin/getVipRightsList",
  saveVipRights: "/admin/saveVipRights",
  updateVipRightsById: "/admin/updateVipRightsById",
  getVipBannerPage: "/admin/getVipBannerPage",
  saveVipBanner: "/admin/saveVipBanner",
  updateVipBannerById: "/admin/updateVipBannerById",
  deleteVipBannerById: "/admin/deleteVipBannerById",
  getMaxSkuByMerchantId: "/admin/getMaxSkuByMerchantId",
  getInvitUserPageByUid: "/admin/getInvitUserPageByUid",
  getVipRecordPage: "/admin/getVipRecordPage",
  confirmExportMerchBill: "/admin/confirmExportMerchBill",
  getMerchInvoicePage: "/admin/getMerchInvoicePage",
  batchConfirmInvoice: "/admin/batchConfirmInvoice",
  getUserVOByUid: "/admin/getUserVOByUid",
  batchSendUserMessage: "/admin/batchSendUserMessage",
  getUserAvatarAuditPage: "/admin/getUserAvatarAuditPage",
  auditUserAvatarById: "/admin/auditUserAvatarById",
  getStatisticalInformation: "/admin/getStatisticalInformation",
  getAdvertisementPage: "/admin/getAdvertisementPage",
  saveAdvertisement: "/admin/saveAdvertisement",
  updateAdvertisementById: "/admin/updateAdvertisementById",
  deleteAdvertisementById: "/admin/deleteAdvertisementById",
  confirmAdvertisementById: "/admin/confirmAdvertisementById",
  cancelAdvertisementById: "/admin/cancelAdvertisementById",
  updateUserBannedByUid: "/admin/updateUserBannedByUid",
  batchSendUserServiceSystemMessageEx: "/admin/batchSendUserServiceSystemMessageEx",
  getUserBalanceByPageByType: "/admin/getUserBalanceByPageByType",
  saveBlockList: "/admin/saveBlockList",
  getBlockListPage: "/admin/getBlockListPage",
  deleteBlockListById: "/admin/deleteBlockListById",
  saveBlockList: "/admin/saveBlockList",
  getCardTemplatePage: "/admin/getCardTemplatePage",
  saveCardTemplate: "/admin/saveCardTemplate",
  updateCardTemplateById: "/admin/updateCardTemplateById",
  deleteCardTemplateById: "/admin/deleteCardTemplateById",
  getCardMaterialPage: '/admin/getCardMaterialPage',
  deleteCardMaterialById: '/admin/deleteCardMaterialById',
  saveCardMaterial: '/admin/saveCardMaterial',
  updateCardMaterialById: '/admin/updateCardMaterialById',
  getWxCode: "/banzhu/getWxCode",
  getUserServiceMessagePageByAdmin: "/admin/getUserServiceMessagePageByAdmin",
  updateUserServiceSystemMessageById: "/admin/updateUserServiceSystemMessageById",
  batchSendUserServiceSystemMessage: "/admin/batchSendUserServiceSystemMessage",
  deleteUserServiceMessageById: "/admin/deleteUserServiceMessageById",
}