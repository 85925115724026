import { createApp } from 'vue'

import App from './App.vue'
import { setupStore } from "@/store";
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import http from "./utils/request"
import url from "./utils/url"

import "./assets/css/reset.css"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/styles/index.less'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(ElementPlus)

setupStore(app)

app.use(router).mount('#app')

app.config.globalProperties.$http = http;
app.config.globalProperties.$url = url;




